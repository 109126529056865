




import Vue from 'vue'
import Loading from '@/components/Loading/index.vue'

export default Vue.extend({
  name: 'AppPreview',
  components: {
    Loading
  },
  created () {
    if (this.$route.query.profileId && this.$route.query.hash) {
      this.$router.push({
        name: 'app-preview',
        params: {
          profileId: this.$route.query.profileId
        },
        query: { hash: this.$route.query.hash }
      })
    } else {
      this.$router.push('/')
    }
  }
})
